import React from "react";
import ReactDOM from "react-dom/client";

import Alert from "./components/Alert/index";

import App from "./App";
import GCVoting from "./GCVoting";

import "./index.css";

/* uncomment for testing locally

  donate (GenD, ClF, RiH, GaB), 
  donate2 (Golf), 
  donate3 (GcAc), 
  donate4 (GcGc, GcSp)
  donate5 (JaG),
	gcVoting
window.nchcfform2Settings = {
	id: "donate",
	donating: "ClF",
	closed: false,
};
*/

if (document.getElementById("nchcfform2-root")) {
	const root = ReactDOM.createRoot(document.getElementById("nchcfform2-root"));

	let element = (
		<Alert data={{ type: "error", text: "An error has occurred." }} />
	);

	if (window.nchcfform2Settings) {
		const { id, donating, closed } = window.nchcfform2Settings;

		if (id === "gcVoting") {
			element = closed ? (
				<Alert
					data={{
						type: "error",
						text: "Golden Cub voting is now closed.",
					}}
				/>
			) : (
				<GCVoting />
			);
		} else if (id === "donate") {
			element = closed ? (
				<Alert
					data={{
						type: "error",
						text: "Donations are currently closed.",
					}}
				/>
			) : (
				<App form={id} donating={donating} />
			);
		} else if (id === "donate2") {
			element = closed ? (
				<Alert
					data={{
						type: "error",
						text: "Donations to The Hills Country Club Play Yellow are currently closed.",
					}}
				/>
			) : (
				<App form={id} />
			);
		} else if (id === "donate3") {
			element = closed ? (
				<Alert
					data={{
						type: "error",
						text: "Golden Cub Ambassador Club donations are currently closed.",
					}}
				/>
			) : (
				<App form={id} />
			);
		} else if (id === "donate4") {
			element = closed ? (
				<Alert
					data={{
						type: "error",
						text: "Golden Cub Golf Classic registration is currently closed.",
					}}
				/>
			) : (
				<App form={id} donating={donating} />
			);
		} else if (id === "donate5") {
			element = closed ? (
				<Alert
					data={{
						type: "error",
						text: "Donations in honor of Joy and Gary are currently closed.",
					}}
				/>
			) : (
				<App form={id} donating={donating} />
			);
		}
	}

	root.render(<React.StrictMode>{element}</React.StrictMode>);
}
