import { useState, useEffect } from "react";
import InputUncontrolled from "../../components/InputUncontrolled";
import Textarea from "../../components/Textarea";

const ClF = ({ total, total2, update, errorsFromDonate }) => {
	const [errors, setErrors] = useState([]);
	const [names, setNames] = useState({});

	useEffect(() => {
		setErrors(errorsFromDonate);
	}, [errorsFromDonate]);

	const handleUpdate = (name, value) => {
		if (name === "amount" && !isNaN(value)) {
			const newAmt = 0 + value;
			setNames((names) => ({ ...names, [name]: value ? value : 0 }));
			total(newAmt);
			//update(name, value ? value : 0);
		} else if (name === "addlAmt" && !isNaN(value)) {
			const newAmt = 0 + value;
			setNames((names) => ({ ...names, [name]: value ? value : 0 }));
			total2(newAmt);
			//update(name, value ? value : 0);
		} else if (name === "addlAmt" && isNaN(value)) {
			const newAmt = 0;
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
			total2(newAmt);
		} else if (name === "other") {
			if (!isNaN(value.replace("$", ""))) {
				const otherAmt = value.replace("$", "");
				const newAmt = 0 + Number(otherAmt);
				setNames((names) => ({
					...names,
					[name]: value ? Number(otherAmt) : 0,
				}));
				total(newAmt * 100);
			}
		} else {
			setNames((names) => ({ ...names, [name]: value ? value : "" }));
			update(name, value ? value : "");
		}
	};

	return (
		<div id="ClF">
			<h3>Donation Amount</h3>

			<div className="one other">
				<InputUncontrolled
					label="Enter Donation Amount"
					req={true}
					name="other"
					update={handleUpdate}
					errors={errors}
				/>
			</div>

			<div className="textarea" style={{ marginTop: "20px" }}>
				<div>
					<Textarea
						label="Comments"
						req={false}
						name="comment"
						value={names.comment}
						update={handleUpdate}
						errors={errors}
					/>
				</div>
			</div>
		</div>
	);
};

export default ClF;
